import revive_payload_client_sNSlKaUF6Y from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VNsTj8ZRnP from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MpJlR3cwxN from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_qlLmTmOMKO from "/app/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.8_typescript@5.6.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_a5V4t7WD4u from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8mmyS9t7Yz from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wHRNPWaABN from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RXHiTvTDmX from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_a3ULklkJy8 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.36.0_typescript@5.6.2_vue@3.5.8_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_IdZtVxvnSG from "/app/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.4.1_@types+node@22.6.1_db0@0.3.1_eslint@9.11.1_jiti@2.4.2__iore_r33cx5wd6mbvvivk555blspfam/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_BtDK2Cluoy from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.36.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import siteConfig_TkgfmR86Ou from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.8_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_OroLiKmfMZ from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.8_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_VHXJY3DkH7 from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.8_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import slideovers_pL9TcRjY7v from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.36.0__brlj2mqgd3djpimy7wdlpokraa/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_bI230a5omR from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.36.0__brlj2mqgd3djpimy7wdlpokraa/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_9ljp0qqge7 from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_qrcode@1.5.4_rollup@4.36.0__brlj2mqgd3djpimy7wdlpokraa/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_E9HxuJ4Nh1 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.36.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_jf2ULRBmki from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sass@1_u2zqo7hmiodmhokfx5po5y67my/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import pwa_icons_VDGUUqsjDD from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sa_ronmuh2kfrfes2tb3eav2nlin4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_MfwKPc54M3 from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.6.1_jiti@2.4.2_sa_ronmuh2kfrfes2tb3eav2nlin4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_3TXCqTAwYD from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.36.0_typesc_ctatvfqnjycyru6jkpaik54qd4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_8CcCirWtnE from "/app/plugins/directives.ts";
import services_eN9fWZLDQ0 from "/app/plugins/services.ts";
import defaults_Fu5J7nfq0m from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.13_magicast@0.3.5_rollup@4.36.0_vue@3.5.8_typescript@5.6.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_sNSlKaUF6Y,
  unhead_VNsTj8ZRnP,
  router_MpJlR3cwxN,
  _0_siteConfig_qlLmTmOMKO,
  payload_client_a5V4t7WD4u,
  navigation_repaint_client_8mmyS9t7Yz,
  check_outdated_build_client_wHRNPWaABN,
  chunk_reload_client_RXHiTvTDmX,
  plugin_vue3_a3ULklkJy8,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IdZtVxvnSG,
  plugin_client_BtDK2Cluoy,
  siteConfig_TkgfmR86Ou,
  inferSeoMetaPlugin_OroLiKmfMZ,
  titles_VHXJY3DkH7,
  slideovers_pL9TcRjY7v,
  modals_bI230a5omR,
  colors_9ljp0qqge7,
  plugin_client_E9HxuJ4Nh1,
  plugin_jf2ULRBmki,
  pwa_icons_VDGUUqsjDD,
  pwa_client_MfwKPc54M3,
  plugin_wy0B721ODc,
  plugin_3TXCqTAwYD,
  directives_8CcCirWtnE,
  services_eN9fWZLDQ0,
  defaults_Fu5J7nfq0m
]